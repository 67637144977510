import React from "react";
import './SearchBar.styles.scss';
import SearchIcon from '../../images/search 1.svg';
import CloseIcon from '../../images/close_24px.svg';

function SearchBar({ searchQuery, handleSearch, clearSearch, backgroundColor, width }) {
  const searchDivStyle = {
    // backgroundColor: backgroundColor || '#FFF',
    // width: width || '65vw',
  };

  return (
    <div className="search-div" style={searchDivStyle}>
      <input
        className="search-bar"
        type="text"
        placeholder='Search'
        onChange={handleSearch}
        value={searchQuery}
      />
      {searchQuery && (
        <span className="clear-icon" onClick={clearSearch}></span>
      )}
    </div>
  );
}

export default SearchBar;
