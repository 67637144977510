import React, { useState,useEffect, useContext, useRef } from "react";
import { parseJSON } from "../../lib/helpers";
import { Link, useParams, useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import { saveData,getData,removeFromIndexedDB } from '../../dataFiles/indexedDB';
import ActionAlerts from "../../components/Alert/Alert.component";
import "./Collections.styles.scss";
import { CollectionContext } from "../../contexts/CollectionContext";
import crossImage from '../../images/Subtract (9).svg'
import Back from "../../images/Arrow back.svg"
import CollectionFieldsWithOptions from "../../components/CollectionFieldsWithOptions/CollectionFieldsWithOptions.component";
import { Tune } from "@material-ui/icons";
import UpArrow from '../../images/Polygon 1.svg'
import SecondaryCustomButton from "../../components/SecondaryCustomButton/SecondaryCustomButton.component";
// import OptimizationTypeBox from "../../components/OptimizationTypeBox/OptimizationTypeBox.component";

function Collections() {
  const {
    fetchCollections,
    setAppApiKey,
    setAppSiteId,
    submitChange,
    isBtnDisabled,
    message,
    btnText,
    showOptimizeBtn,
    showPricingBtn,
  } = useContext(CollectionContext);

  let { api, siteId } = useParams();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  const [sitesData, setSitesData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [disable,setDisable] = useState(true);
  const [buttonClick,setButtonClick] = useState(false);
  const [checkOptimization,setCheckOptimization] = useState(false);
  const [triggerAction, setTriggerAction] = useState(false);
  const [optimizationCompleted, setOptimizationCompleted] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [totalSelectedImages, setTotalSelectedImages] = useState(0);
  const [showNoPlanDialog, setShowNoPlanDialog] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownIconRef = useRef(null);

  const handleButtonClick = () => {
    setTriggerAction(prev => !prev);
  };
  const handleShowNoPlanDialog = () => {
    console.log("handleShowNoPlanDialog called"); // Debug log
    setShowNoPlanDialog(true);
  };
  const onClose = () => {
    setShowNoPlanDialog(false);
    setTriggerAction(false);
  };

const handleFieldSelectionChange = (imageCountChange,selectAll=false) => {
  console.log("Current imageCountChange:", imageCountChange);
  if(selectAll == true){
    setTotalSelectedImages((prevTotal) => {
      const newTotal = imageCountChange;
      return newTotal;
    });
  }else{
    setTotalSelectedImages((prevTotal) => {
      const newTotal = prevTotal + imageCountChange;
      console.log("Calculated new total:", newTotal);
      return newTotal;
    });
  }
};

  useEffect(() => {
    console.log("Updated TS:", totalSelectedImages);
  }, [totalSelectedImages]);
  const handleSelectAllClick = () => {
    if(!disable){
      console.log("selectALL: ",selectAllChecked)
      if(selectAllChecked === true){
        setButtonClick(true)
      }else{
        setButtonClick(false)
      }
      setSelectAllChecked(!selectAllChecked);
    }
  };

  const backButtonRef = useRef(null);

  const handleBackButtonClick = async () => {
    if (!checkOptimization) {
      // localStorage.removeItem("collectionDataState")
      localStorage.removeItem('optimizationStatus')
      localStorage.removeItem("jobId");
      localStorage.removeItem('totalFields');
      await removeFromIndexedDB("collectionDataState");
    }
  };
  const toggleDropdown = (event) => {
    event.stopPropagation();
    setShowDropdown((prev) => !prev);
  };
  
  const handleOutsideClick = (event) => {
    if (
      dropdownRef.current && 
      !dropdownRef.current.contains(event.target) &&
      dropdownIconRef.current &&
      !dropdownIconRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };
  
  useEffect(() => {  
    if (showDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
  
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showDropdown]);
  

  useEffect(() => {
    if (backButtonRef.current) {
      backButtonRef.current.addEventListener("click", handleBackButtonClick);
    }

    return () => {
      if (backButtonRef.current) {
        backButtonRef.current.removeEventListener("click", handleBackButtonClick);
      }
    };
  }, [checkOptimization]);

  useEffect(() => {
    const handleCustomCheckboxChange = (event) => {
      const isChecked = event.detail;
        setSelectAllChecked(isChecked)
        setButtonClick(false)
    };
  
    document.addEventListener('checkboxChange', handleCustomCheckboxChange);
  
    return () => {
      document.removeEventListener('checkboxChange', handleCustomCheckboxChange);
    };
  }, []);

  useEffect(() => {
    const handleOptimizationCompleted = (event) => {
      setOptimizationCompleted(true);
    };
  
    document.addEventListener('optimizationCompleted', handleOptimizationCompleted);
  
    return () => {
      document.removeEventListener('optimizationCompleted', handleOptimizationCompleted);
    };
  }, []);

  useEffect(() => {

    const handleDisable = () => {
      setDisable(false)
      setCheckOptimization(false)
    };

    document.addEventListener('collectionData', handleDisable);

    return () => {
      document.removeEventListener('collectionData', handleDisable);
    };
  }, []);
  useEffect(() => {
    const fetchSites = async () => {
        const data = await getData('sites');
        if (data) {
            const filteredSites = data.filter(site => site.siteUniqueId !== siteId);
            setSiteList(filteredSites);
        }
    };

    fetchSites();
}, [siteId]);

  useEffect(() => {

    const handleOptimizationInProgress = () => {
      setDisable(true)
      setCheckOptimization(true)
    };

    document.addEventListener('optimizationInProgress', handleOptimizationInProgress);

    return () => {
      document.removeEventListener('optimizationInProgress', handleOptimizationInProgress);
    };
  }, []);

  useEffect( () => {
    setAppApiKey(api); 
    setAppSiteId(siteId);
    let collections;
    (async () => {
      collections = await getData("collectionDataState");
      console.log((collections));
      if(collections === null || collections === undefined){
        fetchCollections({ api, siteId });
      }else{
        const parsedCollection = JSON.parse(collections);
        if(parsedCollection.collectionData.length === 0){
          fetchCollections({ api, siteId });
        }
      }
    })();

    (async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}api/user-sites/${api}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: window.localStorage.getItem("token"),
        },
      }
    );
    const data =  await response.json();
    setSitesData(data);
    })();
  }, []);
  let SiteName = "";
  if (sitesData.mappedSitesData) {
    const matchedSite = sitesData.mappedSitesData.find(data => data.siteUniqueId === siteId);
    SiteName = matchedSite ? matchedSite.name : "";
  }
  // const toggleSelectAll = () => {
  //   const updatedCollectionData = collectionData.map(collection => ({
  //     ...collection,
  //     collectionFields: collection.collectionFields.map(field => ({
  //       ...field,
  //       isChecked: !selectAllChecked,
  //     })),
  //   }));
  //   updateCollectionData(updatedCollectionData);
  //   setSelectAllChecked(!selectAllChecked);
  // };
  const handleSiteClick = (siteId, siteUniqueId) => {
    localStorage.removeItem('optimizationStatus')
    localStorage.removeItem("jobId");
    localStorage.removeItem('totalFields');
    removeFromIndexedDB("collectionDataState").catch((error) =>{
      console.error("Error clearing collectionDataState:", error);
    })
    history.push(`/pixie/${siteId}/collections/${siteUniqueId}?type=${type}`);
    setShowDropdown(false);
    window.location.reload()
  };
    return (
    <div className="section-main">
    <div className="main-block-new">
        <div className="site-details-section">
          <div className="left-site-details-section">
              {/* <Link to={type === 'convert'? '/convert-to-webp' : '/pixie'}>
              <button className="back-button" ref={backButtonRef}><img src={Back}></img></button>
              </Link> */}
              <div className="sites-upper-container">
                <div className="site-name">
                  {SiteName}
                </div>
                {!checkOptimization && (
                  <div className="sites-dropdown" onClick={toggleDropdown} ref={dropdownIconRef}>
                    <img className="sites-dropdown-arrow" src={UpArrow} alt="Up Arrow"/>
                  </div>
                )}
              </div>
              {showDropdown && (
                    <div className="dropdown-content" ref={dropdownRef}>
                        {siteList.map((site) => (
                          // <Link to={`/pixie/${site.siteId}/collections/${site.siteUniqueId}?type=optimize`}>
                            <div
                                key={site.siteUniqueId}
                                className="dropdown-item"
                                onClick={() => handleSiteClick(site.siteId, site.siteUniqueId)}
                            >
                                {site.name}
                            </div>
                          // </Link>
                        ))}
                    </div>
                )}
              {!checkOptimization ? 
                <div className="sub-heading-section">
                  <p className="head-lite">
                    Select the items you would like to {type === 'convert' ? 'convert' : 'optimize'}
                  </p>
                  <p className="info-text">
                    You will not be charged for the images that are already {type === 'convert' ? 'converted' : 'optimized'}.
                  </p>
                </div>:
                optimizationCompleted ? 
                <div className="result-subheading">Your optimization is completed!</div> : 
                <div className="result-subheading">Please wait while your images are optimizing...</div>
              }

          </div>
          {!checkOptimization &&(

          <div className="right-site-details-section">
            <div 
              className="select-all" 
              onClick={handleSelectAllClick}
            >
              {(selectAllChecked) ? "Deselect All" : "Select All"}
            </div>
            <div className="right-down-site-section">
              <SecondaryCustomButton customClasses={'optimize-now'} onClick={handleButtonClick}>Optimize Now</SecondaryCustomButton>
              <p className="selected-images">Images Selected: <span className="images-count">{totalSelectedImages}</span></p>
            </div>
            
            {/* <OptimizationTypeBox /> */}
            {/* <div className="alerts">
              {message.msg && (
                <ActionAlerts text={message.msg} type={message.type} />
              )}
            </div> */}        
            {/* <div className="nxt-prv">
            <div className="button-container">

            {showOptimizeBtn && (
              <CustomButton
                onClick={submitChange}
                mr={true}
                disabled={isBtnDisabled}
                black={!isBtnDisabled}
              >
                {btnText}
              </CustomButton>
            )}
            {showPricingBtn && (
              <Link to="/pixie/pricing">
                <CustomButton black={true}>Upgrade Plan</CustomButton>
              </Link>
            )}
            <CustomButton onClick={goBackBtn} black={true} mr={true}>
                Back
              </CustomButton>
            </div>

            </div> */}
          </div>
          )}
        </div>

            <CollectionFieldsWithOptions selectAllChecked={selectAllChecked} byButtonClick={buttonClick} type={type} triggerAction={triggerAction} onFieldSelectionChange={handleFieldSelectionChange} onShowNoPlanDialog={handleShowNoPlanDialog}/>
    </div>
    {
      showNoPlanDialog && (
    <div className="overlay" onClick={onClose}>
      <div className="no-plan-optimization-wrapper">
        <img src={crossImage} className="dialog-box-pricing-cross" onClick={onClose}></img>
        <div className='no-plan-top-wrapper'>
            <p className="dialog-box-message-heading">No active plan</p>
            <p className="dialog-box-message-text">It looks like you don’t have an active plan!<br></br>
            To continue optimizing and unlocking full features, check out our pricing and choose a plan that suits you!</p>
        </div>
        {/* {buttonVisible ?(<div className="dialog-box-buttons">
          <button className="confirmButton" onClick={clickPrimaryButton}>{textPrimaryButton}</button>
          <button className="cancelButton" onClick={onClose}>{textSecondaryButton}</button>
        </div>) : null} */}
        <button className="plans-button" onClick={() => window.location.href = '/pixie/pricing'}>Explore pricing plans</button>
      </div>
    </div>
          )
        }
    </div>
  );
}

export default Collections;
