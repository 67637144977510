import React ,{ useEffect,useState }from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { parseJSON } from "../../lib/helpers";
import Bugsnag from "@bugsnag/js";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Modal from "../../components/Modal/Modal.component";
import OauthBtn from "../../components/OauthBtn/OauthBtn.component";
import { Link ,useLocation } from "react-router-dom";
import OptimizerImage from '../../images/optimizer_icon.svg';
import LogHistory from '../../images/log_history_icon.svg';
import WebPImage from '../../images/webp_icon.svg';
import ProfileIcon from '../../images/profile_icon.svg';
import CreditIcon from '../../images/credit_icon.svg';
import BillingIcon from '../../images/billing_icon.svg';
import HelpIcon from '../../images/help_icon.svg';
import logOutIcon from '../../images/log_out_icon.svg';
import OptimizerSelectedIcon from '../../images/optimizer_selected_icon.svg';
import LogHistorySelectedIcon from '../../images/log_history_selected_icon.svg';
import WebpSelectedIcon from '../../images/webp_selected_icon.svg';
import ProfileSelectedIcon from '../../images/profile_selected_icon.svg';
import CreditSelectedIcon from '../../images/credits_selected_icon.svg';
import BillingSelectedIcon from '../../images/billing_selected_icon.svg';
import HelpSelectedIcon from '../../images/help_selected_icon.svg';
import logOutSelectedIcon from '../../images/log_out_selected_icon.svg';
import CustomButton from '../CustomButton/CustomButton.component';
import PixieIcon from '../../images/pixie icon.png';
import PixieText from '../../images/Pixie.svg';
import './NavMenu.styles.scss';

const NavMenu = ({ onRouteChange, toggleModal }) => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [siteIds, setSiteIds] = useState([]);
  const [lastSubpage, setLastSubpage] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeOpenModal = () => {
    setIsModalOpen(prevState => !prevState);
}
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMouseEnter = () => {
    setIsExpanded(true); // expand the menu on hover
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };
  console.log("LastSubpage: ",lastSubpage);
  useEffect(() => {
    console.log(location.pathname);
    setSelectedOption(location.pathname);
    
    if (location.pathname.startsWith('/pixie/') && !location.pathname.includes('/pixie/pricing')) {
      setLastSubpage(location.pathname);
    }else if(location.pathname === '/pixie'){
      setLastSubpage(null)
    }
  }, [location]);
  
//   useEffect(() => {
//     fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-username-sites`, {
//         method: "GET",
//         headers: {
//             "Content-Type": "application/json",
//             token: window.localStorage.getItem("token"),
//         },
//     })
//         .then(parseJSON) // parse it to Json
//         .then((data) => {
//             if (data.success) {
//                 const {
//                     user: { sites, username },
//                 } = data;
//                 // if (sites instanceof Array && sites.length < 1) {
//                     // window.location = url;
//                 // }
//                 setSiteIds(sites);
//             }
//         })
//         .catch(err => {
//             console.log({ err });
//             Bugsnag.notify(err);
//         });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);
  const handleOptionClick = (path) => {
    if (path !== '/help') {
      setSelectedOption(path);
    }
    if (path === '/help') {
      window.open('https://www.trypixie.io/guide', '_blank');
    }
  };

  useEffect(() => {
    console.log(selectedOption);
    if(selectedOption === '' || selectedOption ===  null || selectedOption === undefined){
      setSelectedOption(location.pathname)
    }
    else if(!selectedOption.includes('/pixie/pricing')){
      const nestedPath = location.pathname.split('/')[1];
      setSelectedOption(`/${nestedPath}`);
    }else if(selectedOption.includes('/pixie/pricing')){ 
      setSelectedOption(`/pixie/pricing`);
    }
  }, [location]);

  // return (
  //   <div>
  //     <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
  //       <MenuRoundedIcon style={{ color: '#000000' }} fontSize="large" />
  //     </Button>
  //     <Menu
  //       id="simple-menu"
  //       anchorEl={anchorEl}
  //       keepMounted
  //       open={Boolean(anchorEl)}
  //       onClose={handleClose}
  //     >
  //       <MenuItem><Link to="/pixie/pricing" style={{
  //         textDecoration: "none",
  //         color: "rgb(33, 33, 33)"
  //       }}>View Pricing</Link></MenuItem>
  //       <MenuItem onClick={toggleModal}>View Profile</MenuItem>
  //       <MenuItem onClick={() => onRouteChange('signout')}>Log Out</MenuItem>
  //     </Menu>
  //   </div>
  // );
  
  return (
    <div className={`nav-menu ${isExpanded ? '' : 'shrink'}`}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}>
    <div className="navMenuContainer">
      {console.log("SO: ",selectedOption)}
      <div className="menuBox">
        <Link to={'/pixie'} className='logo-link'>
        <div className='logo'>
          <img className="icon" src={PixieIcon}/>
          <img className="pixieText" src={PixieText}/>
        </div>
        </Link>
        <div className='items'>
        <div className='items-container padding-top-79px'>
          <div className={`menuItem ${selectedOption === '/pixie' && !selectedOption.includes('/pixie/pricing') && (type !== 'convert') ? 'selected' : ''}`}>
            <Link
            to={lastSubpage || '/pixie'} // Use lastSubpage if available, otherwise the default URL
            className="menuLink"
            onClick={() => handleOptionClick(lastSubpage || '/pixie')}
          >
            <div className='icon-wrapper'>
              <img src={selectedOption === '/pixie' ? OptimizerSelectedIcon : OptimizerImage} alt="Icon 1" className="customIcon" />
            </div>
            <span className='text-field'>Image Optimizer</span>
            </Link>
            </div>
            <div className={`menuItem ${selectedOption === '/logs' ? 'selected' : ''}`}>
              <Link to="/logs" className="menuLink" onClick={() => handleOptionClick('/logs')}>
                <div className='icon-wrapper'>
                  <img src={selectedOption === '/logs' ? LogHistorySelectedIcon : LogHistory} alt="Icon 2" className="customIcon" />
                </div>
                <span className='text-field'>Log History</span>
              </Link>
            </div>
            <div className={`menuItem ${selectedOption === '/convert-to-webp' || type === 'convert' ? 'selected' : ''}`}>
              <Link to="/convert-to-webp" className="menuLink" onClick={() => handleOptionClick('/convert-to-webp')}>
                <div className='icon-wrapper'>
                  <img src={(selectedOption === '/convert-to-webp' || type === 'convert') ? WebpSelectedIcon : WebPImage} alt="Icon 2" className="customIcon"  />
                </div>
                <span className='text-field'>Convert To WebP</span>
              </Link>
            </div>
            </div>
          <div className='divider'></div>
          <div className='items-container'>
            <div className={`menuItem ${selectedOption === '/profile' ? 'selected' : ''}`}>
              <Link to="/profile" className="menuLink" onClick={() => handleOptionClick('/profile')}>
                <div className='icon-wrapper'>
                  <img src={selectedOption === '/profile' ? ProfileSelectedIcon : ProfileIcon} alt="Icon 5" className="customIcon" />
                </div>
                <span className='text-field'>Profile</span>
              </Link>
            </div>  
            <div className={`menuItem ${selectedOption === '/credits' ? 'selected' : ''}`}>
              <Link to="/credits" className="menuLink" onClick={() => handleOptionClick('/credits')}>
                <div className='icon-wrapper'>
                  <img src={selectedOption === '/credits' ? CreditSelectedIcon : CreditIcon} alt="Icon 5" className="customIcon" />
                </div>
                <span className='text-field'>Credits</span>
              </Link>
            </div>     
            <div className={`menuItem ${selectedOption.includes('/pixie/pricing') ? 'selected' : ''}`}>
              <Link to="/pixie/pricing" className="menuLink" onClick={() => handleOptionClick('/pixie/pricing')}>
                <div className='icon-wrapper'>
                  <img src={selectedOption.includes('/pixie/pricing') ? BillingSelectedIcon : BillingIcon} alt="Icon 4" className="customIcon" />
                </div>
                <span className='text-field'>Billing & Subscription</span>
              </Link>
            </div>
            <div
              className={`menuItem ${selectedOption === '/help' ? 'selected' : ''}`}
              onClick={() => handleOptionClick('/help')}
            >
              <div className='menuLink cursor-poniter'>
                <div className='icon-wrapper'>
                  <img src={selectedOption === '/help' ? HelpSelectedIcon : HelpIcon} alt="Icon 3" className="customIcon" />
                </div>
                <span className='text-field'>Help</span>
              </div>

          </div>
        </div>
          <div className='logout-container'>
            <div className="menuItem" onClick={() => onRouteChange('signout')}>
              <div className='menuLink cursor-poniter'>
                <div className='icon-wrapper'>
                  <img src={logOutIcon} alt="Icon 6" className="customIcon" />
                </div>
                <span className='text-field'>Log Out</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      {
                isModalOpen && (
                    // <Modal>
                        <MultiOptionBox toggleModal={closeOpenModal} />
                    // </Modal>
                )
            }
    </div>
  );
}
const MultiOptionBox = ({ toggleModal }) => {
  return (
      <div className="box-outline" id="modal-div">
          <div className="inner-box">
              <div onClick={toggleModal} className="modal-close">
                  <CloseSharpIcon fontSize="large" style={{ color: '#FACE48' }} />
              </div>

              <h3 className="title">
                  Add your Webflow site
              </h3>
              <p className="desc">
                  It is as simple as signing in your Webflow account.
              </p>
              <div className="btn-box">

                  <OauthBtn />
                  <span className="or">or</span>
                  <Link className="custom-links" to="/pixie/add-api-key">
                      <CustomButton black={true}>Add API Key</CustomButton>
                  </Link>
              </div>
          </div>
      </div>
  )
}
export default NavMenu;